import  { Component } from "react";
import stl from "./SingleQuest.module.css";

class SingleQuest extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <div
          onClick={this.props.onClick}
          className={[
            stl.singleFaqContainer,
            this.props.showAns ? stl.expanded : undefined,
            this.props.faqsPage ? stl.faqsPage : "",
            stl.StyleMod,
          ].join(" ")}
        >
          <strong>{this.props.qust}</strong>
          {this.props.showAns && <p>{this.props.ans}</p>}
        </div>
      </>
    );
  }
}

export default SingleQuest;
